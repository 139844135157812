<template>
  <b-modal
    id="add-new-banner-sidebar"
    title="add new banner"
    v-model="showModal"
    hide-footer
    bg-variant="white"
    @hidden="$emit('close')"
    sidebar-class="sidebar-lg"
  >
    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer ref="refFormObserver" >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
        >
          <!-- Banner name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <template #label>{{$t('labels.name')}}</template>
              <b-form-input
                id="name"
                v-model="bannerData.name"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              :label="$t('labels.description')"
              label-for="description"
            >
              <template #label>{{$t('labels.description')}}</template>
              <b-form-input
                id="description"
                v-model="bannerData.description"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- URL Image -->
          <validation-provider
            #default="validationContext"
            name="UrlImage"
            rules="required|url"
          >
            <b-form-group
              :label="$t('labels.image')"
              label-for="imagen"
            >
              <template #label>{{$t('labels.image')}}</template>
              <b-form-file
                v-model="bannerData.image"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- type -->
          <validation-provider
            #default="validationContext"
            name="Type"
            rules="required"
          >
            <b-form-group
              :label="$t('labels.type')"
              label-for="type"
            >
              <template #label>{{$t('labels.type')}}</template>
              <b-form-select
                id="type"
                :options="optionsType"
                v-model="bannerData.type"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{$t('buttons.update')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              
              @click="$emit('close')"
            >
              {{$t('buttons.cancel')}}
            </b-button>
          </div>
          
        </b-form>
      </validation-observer>
      
    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormFile, BFormInvalidFeedback, BButton, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import axios from "@/libs/axios";
import {translatableText} from "@core/utils/utils";
import {axiosErrorHandle} from "@core/utils/errorHandler";

export default {
  props:['banner','show'],
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  watch:{
    show(val){
      this.showModal = val
    },
    banner(val){
      this.bannerData=val
    }
  },
  data() {
    return {
      showModal:false,
      bannerData:{},
      required,
      alphaNum,
      countries,
      bannerExist: '',
      optionsType: [
        { value: 'header', text: 'Header' },
        { value: 'popup', text: 'Pop up' }
      ],
    }
  },
  methods:{
    uploadImage(){
      const formData = new FormData();
      formData.append('images', this.bannerData.image)
      formData.append("path", "banner");
      return axios.post(
        `setImages/${store.state.whitelabelCurrencyNabvar.whitelabel.client}`,
          formData
      )
    },

    onSubmit(){
      this.uploadImage()
          .then(response=>{
              const form = new FormData()
              const data = this.bannerData
              const id = this.banner._id
              console.log(data)
              form.append('name', data.name)
              form.append('type', data.type)
              form.append('description', data.description)
              form.append('urlImage', response.data.files[0])
              form.append('client', store.state.whitelabelCurrencyNabvar.whitelabel.client)
              store.dispatch('app-banner/updateBanner', {id, bannerData:form})
                  .then( () => {
                      this.$emit('refetch-data')
                      this.$emit('update:is-add-new-banner-sidebar-active', false)
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: 'Banner edited',
                              icon: 'EditIcon',
                              variant: 'success',
                          },
                      })
                      this.showModal = false
                  }).catch(async (error) => {
                this.$emit('update:is-add-new-banner-sidebar-active', false)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('banner_edit_fail'),
                    text: await translatableText({text: axiosErrorHandle(error)}),
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              })
          })

    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';

#add-new-banner-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
